// src/stores/auth.js
import { defineStore } from 'pinia'
import { ref, computed } from 'vue'
import { firebaseSetup } from '@/config/firebase'
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  updateProfile,
  onAuthStateChanged,
  setPersistence,
  //browserLocalPersistence,
  browserSessionPersistence
} from 'firebase/auth'
import { GET_WORKING_LANGUAGE, UPDATE_WORKING_LANGUAGE } from '@/graphql/userQueries'
import { useNotification } from '@/composables/useNotification'
import { getWorkingLanguageId } from '@/utils/handy'

//import { useUserStore } from './config/userStore'

//import { useUserStore } from './config/userStore'

const auth = getAuth(firebaseSetup)

export const useAuthStore = defineStore('authStore', () => {
  const { addNotification, setLoading } = useNotification()

  const user = ref(JSON.parse(sessionStorage.getItem('user')))
  //const user = ref(null)

  const isInitialized = ref(false)

  const workingLanguage = ref(getWorkingLanguageId())

  const initializeAuth = async () => {
    await setPersistence(auth, browserSessionPersistence)
    //console.log('Persistence set to LOCAL');

    return new Promise((resolve) => {
      onAuthStateChanged(auth, (currentUser) => {
        if (currentUser) {
          setUser(currentUser)
        } else {
          clearUser()
        }
        isInitialized.value = true
        resolve()
      })
    })
  }

  const createAccount = async (email, password) => {
    setLoading(true)
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password)

      setUser(userCredential.user)
      addNotification('info', 'Success')
    } catch (e) {
      addNotification('error', e.message)
    } finally {
      setLoading(false)
    }
  }

  const login = async (email, password) => {
    setLoading(true)
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password)

      if (userCredential) {
        setUser(userCredential.user)

        await getWorkingLanguage()

        return userCredential.user.uid
      }

      return false
      // if (userCredential) {
      //   const userStore = useUserStore()
      //   userStore.updateUserLogin(userCredential.user.uid)
      // }
    } catch (e) {
      addNotification('error', e.message)
    } finally {
      setLoading(false)
    }
  }

  const setUser = (currentUser) => {
    user.value = currentUser
    sessionStorage.setItem('user', JSON.stringify(currentUser))
  }

  const clearUser = () => {
    user.value = null
    sessionStorage.removeItem('user')
  }

  const logout = async () => {
    setLoading(true)
    try {
      await signOut(auth)
      clearUser()
    } catch (e) {
      addNotification('error', e.message)
    } finally {
      setLoading(false)
    }
  }

  const update = async (payload) => {
    setLoading(true)
    const { displayName } = payload
    try {
      await updateProfile(auth.currentUser, { displayName })
      setUser(auth.currentUser)
      addNotification('info', 'Success')
    } catch (e) {
      addNotification('error', e.message)
    } finally {
      setLoading(false)
    }
  }

  const getToken = async () => {
    if (auth.currentUser) {
      setLoading(true)
      try {
        const token = await auth.currentUser.getIdToken(true) // Force refresh the token
        //console.log('token', token);
        return token
      } catch (e) {
        addNotification('error', e.message)
        throw new Error('User is not authenticated')
      } finally {
        setLoading(false)
      }
    } else {
      addNotification('error', 'User is not authenticated')
      throw new Error('User is not authenticated')
    }
  }

  const refreshToken = async () => {
    const user = auth.currentUser
    if (user) {
      await user.getIdToken(true) // Force refresh the token
    }
  }

  const isLoggedIn = computed(() => !!user.value)

  const getWorkingLanguage = async () => {
    setLoading(true)
    try {
      const { executeGraphQL } = await import('@/utils/graphqlUtils')
      const data = await executeGraphQL(GET_WORKING_LANGUAGE)

      if (data && data.getWorkingLanguage) {
        workingLanguage.value = data.getWorkingLanguage

        sessionStorage.setItem('workingLanguage', workingLanguage.value)

        //addNotification('info', 'Success')
      }
    } catch (e) {
      addNotification('error', e.message)
    } finally {
      setLoading(false)
    }
  }

  const updateWorkingLanguage = async (new_language_id) => {
    setLoading(true)
    try {
      const { executeGraphQL } = await import('@/utils/graphqlUtils')
      const data = await executeGraphQL(UPDATE_WORKING_LANGUAGE, {
        workinglang_id: Number.parseInt(new_language_id)
      })

      if (data && data.updateWorkingLanguage) {
        workingLanguage.value = new_language_id

        sessionStorage.setItem('workingLanguage', workingLanguage.value.toString())

        addNotification('info', 'Success')
        return new_language_id
      }
    } catch (e) {
      addNotification('error', e.message)
      return 1
    } finally {
      setLoading(false)
    }
  }

  return {
    user,
    workingLanguage,
    isLoggedIn,
    isInitialized,
    createAccount,
    login,
    setUser,
    clearUser,
    logout,
    update,
    getToken,
    refreshToken,
    getWorkingLanguage,
    updateWorkingLanguage,
    initializeAuth
  }
})
