<script setup lang="ts">
import { mdiForwardburger, mdiBackburger, mdiMenu } from '@mdi/js'
import { ref, Suspense } from 'vue'
import { useRouter } from 'vue-router'
import menuAside from '@/menuAside'
import menuNavBar from '@/menuNavBar.js'
import AsyncMenu from '@/components/AsyncMenu.vue' // We'll create this component next
import { useDarkModeStore } from '@/stores/darkMode.js'
import BaseIcon from '@/components/BaseIcon.vue'
import FormControl from '@/components/FormControl.vue'
import NavBar from '@/components/NavBar.vue'
import NavBarItemPlain from '@/components/NavBarItemPlain.vue'
import AsideMenu from '@/components/AsideMenu.vue'
import FooterBar from '@/components/FooterBar.vue'
import { useAuthStore } from '@/stores/authStore'
import { useMenu } from '@/composables/useMenu'
import NotificationCenter from '@/components/NotificationCenter.vue'

const { filteredMenu } = useMenu()

const { isLoading } = useMenu()
const layoutAsidePadding = 'xl:pl-60'

const darkModeStore = useDarkModeStore()

const router = useRouter()

const authStore = useAuthStore()

const isAsideMobileExpanded = ref(false)
const isAsideLgActive = ref(false)

router.beforeEach(() => {
  isAsideMobileExpanded.value = false
  isAsideLgActive.value = false
})

const menuClick = async (_event: Event, item: any) => {
  if (item && item.isToggleLightDark) {
    darkModeStore.set()
  }

  if (item && item.isLogout) {
    await authStore.logout()
    router.push('/login')
  }
}
</script>

<template>
  <div
    :class="{
      'overflow-hidden lg:overflow-visible': isAsideMobileExpanded
    }"
  >
    <NotificationCenter />
    <div
      :class="[layoutAsidePadding, { 'ml-60 lg:ml-0': isAsideMobileExpanded }]"
      class="flex flex-col min-h-[calc(100vh)] pt-14 w-screen transition-position lg:w-auto bg-gray-50 dark:bg-slate-800 dark:text-slate-100"
    >
      <NavBar
        :menu="menuNavBar"
        :class="[layoutAsidePadding, { 'ml-60 lg:ml-0': isAsideMobileExpanded }]"
        @menu-click="menuClick"
      >
        <NavBarItemPlain
          display="flex lg:hidden"
          @click.prevent="isAsideMobileExpanded = !isAsideMobileExpanded"
        >
          <BaseIcon :path="isAsideMobileExpanded ? mdiBackburger : mdiForwardburger" size="24" />
        </NavBarItemPlain>
        <NavBarItemPlain display="hidden lg:flex xl:hidden" @click.prevent="isAsideLgActive = true">
          <BaseIcon :path="mdiMenu" size="24" />
        </NavBarItemPlain>
        <NavBarItemPlain use-margin>
          <FormControl placeholder="Search (ctrl+k)" ctrl-k-focus transparent borderless />
        </NavBarItemPlain>
      </NavBar>

      <Suspense>
        <template #default>
          <AsyncMenu
            v-if="!isLoading"
            v-model:is-aside-mobile-expanded="isAsideMobileExpanded"
            v-model:is-aside-lg-active="isAsideLgActive"
            @menu-click="menuClick"
            @aside-lg-close-click="isAsideLgActive = false"
            @update:is-aside-mobile-expanded="(newV) => (isAsideMobileExpanded = newV)"
            @update:is-aside-lg-active="(newV) => (isAsideLgActive = newV)"
          />
        </template>
        <template #fallback>
          <div>Loading menu...</div>
        </template>
      </Suspense>
      <div class="flex-grow h-full">
        <slot />
      </div>
    </div>
  </div>
</template>
